<template>
	<standard-container title="valutazioni" description="" menuicon="valutazioni">
		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-row :dense="compactVisualization">
					<v-col cols="12" md="6">
						<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" label="Ricerca" single-line hide-details clear-icon="mdi-close-circle" clearable />
					</v-col>
				</v-row>
				<v-data-table 
					dense
					class="mt-6"
					:items="valutazioni" 
					:headers="gTranslateTableHeader(tableHeaders)"
					:search="search" 
					:sort-by="['DataCreazione']" 
					:sort-desc="[true]"
					:page.sync="page"
					:items-per-page="itemsPerPage"
					item-key="Id"
					hide-default-footer
					@click:row="editItem"
					@page-count="pageCount = $event"
				>
					<template v-slot:item.DescrizioneValutazione="{ item }">
						<v-chip x-small :color="item.ColoreValutazione" class="text-uppercase white--text" :title="item.CodiceValutazione">
							{{ item.DescrizioneValutazione }}
						</v-chip>
					</template>
					<template v-slot:item.RisultatoValutazione="{ item }">
						<div v-if="item.CodiceLivelloAtteso>=0">
							<!-- <v-icon v-if="item.CodiceValutazione >= item.CodiceLivelloAtteso" color="success" title="Valutazione positiva (livello raggiunto maggiore o uguale a quello atteso)">mdi-check-circle</v-icon> -->
							<v-icon v-if="item.CodiceValutazione < item.CodiceLivelloAtteso" color="error" :title="gTranslate('Valutazione negativa (livello inferiore a quello atteso)')">
								mdi-alert
							</v-icon>
						</div>
					</template>
					<template v-slot:item.DescrizioneLivelloAtteso="{ item }">
						<div v-if="item.CodiceLivelloAtteso>=0" class="text-right">
							<v-chip x-small outlined color="grey" class="text-uppercase" :title="item.CodiceLivelloAtteso">
								{{ item.DescrizioneLivelloAtteso }}
							</v-chip>
						</div>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		
		<!-- footer -->
		<template v-slot:footer>
			<v-row class="mt-1">
				<v-col cols="6" sm="2" order="2" order-sm="1">
					<v-btn small class="mt-3" icon :title="gTranslate('Ricarica la lista')" :disabled="loading" :loading="loading" @click="refreshList">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" sm="8" order="1" order-sm="2">
					<v-pagination v-model="page" circle color="warning" :length="pageCount" :disabled="itemsPerPage === -1" />
				</v-col>
				<v-col cols="6" sm="2" order="3" order-sm="3">
					<div class="d-flex justify-end">
						<div style="max-width:100px">
							<v-select v-model="itemsPerPage" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="itemsPerPageOptions" :label="gTranslate('Righe')" />
						</div>
					</div>
				</v-col>
			</v-row>
		</template>

		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="valutazioni">
						{{ valutazioni }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>

		<!-- inizio dialog -->
		<v-dialog v-if="currentValutazione" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-text="currentValutazione.Id<0 ? gTranslate('Nuova valutazione') : gTranslate('Modifica valutazione')" />
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loading" :loading="loading" @click="updateItem">
							{{ gTranslate('Save') }}
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="12">
							<span class="headline">{{ currentValutazione.PersonaExt }}</span>
						</v-col>
						<v-col cols="12" lg="12">
							<span class="title">{{ currentValutazione.DescrizioneJob }}</span> / <span class="title">{{ currentValutazione.DescrizioneCompetenzaExt }} </span>
						</v-col>
					</v-row>

					<v-row dense class="mt-5">
						<v-col cols="12" lg="6">
							<hr-date-picker v-model="currentValutazione.DataValutazione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Data valutazione')" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-select v-model="currentValutazione.CodiceValutazione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Codice valutazione')" :items="decodifiche.LivelliCompetenzePerBind" item-text="Label" item-value="Codice" item-color="Color" />
							<div v-if="currentValutazione.DescrizioneCompetenzaExt && currentValutazione.CodiceLivelloAtteso>0" class="text-right pr-1">
								{{ gTranslate('Livello atteso') }}: <v-chip x-small outlined :color="currentValutazione.ColoreLivelloAtteso" class="text-uppercase">
									{{ currentValutazione.DescrizioneLivelloAtteso }}
								</v-chip>
							</div>
						</v-col>
					</v-row>

					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12" lg="6">
								<debug-panel label="Current Valutazione">
									{{ currentValutazione }}
								</debug-panel>
							</v-col>
							<v-col cols="12" lg="6">
								<debug-panel label="." />
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->			
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import DebugContainer from '@/components/debug-container'
import DebugPanel from '@/components/debug-panel'
import HrDatePicker from '@/components/hr-date-picker'
export default {
	name: 'ValutazioniPage',
	components: { StandardContainer, DebugContainer, DebugPanel, HrDatePicker },
	data: () => ({
		currentTab:null,
		dialog: false,
		loading: false, // loading viene usata sia per il refresh che per il save ed agisce sui due pulsanti relativi
		search: '',
		page: 1,
		pageCount: 0,
		itemsPerPage: 20,
		valutazioni: [],
		currentValutazione: {},
		itemsPerPageOptions: [{text:'5', value:5},{text:'10', value:10},{text:'20', value:20},{text:'50', value:50},{text:'100', value:100},{text:'Tutti', value:-1}],	
		tableHeaders: [
			{ text: 'Data', value: 'DataValutazioneExtended'},
			{ text: 'Persona', value: 'PersonaExt' },
			{ text: 'Job', value: 'DescrizioneJob' },
			{ text: 'Competenza', value: 'DescrizioneCompetenzaExt'},
			{ text: 'Valutazione', value: 'DescrizioneValutazione' },
			{ text: '', value: 'RisultatoValutazione', width:20 },
			{ text: 'Livello atteso', value: 'DescrizioneLivelloAtteso' },
		],
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		competenze() { return this.$store.getters['competenze'] },
		persone() { return this.$store.getters['persone'] },
	},
	mounted() {
		this.initData()
		this.$refs.searchField.focus()
	},
	methods: {
		// carica i dati dallo store (disacoppiandoli) e poi cicla la tabella risultante per caricare le decodifiche necessarie
		initData() {
			this.$set(this, 'valutazioni', JSON.parse(JSON.stringify(this.$store.getters['valutazioni'])))
			this.valutazioni.forEach(item => {
				// barbatrucco bello! carica i dati estesi prima di passarli alla datatable, in questo modo diventano filtrabili!!
				this.$set(item, 'DataValutazioneExtended', this.gStandardDate(item.DataValutazione))
				this.$set(item, 'DescrizioneJob', this.decodificaDescrizioneJob(item.CodiceJob))
				this.$set(item, 'DescrizioneValutazione', this.decodificaEtichettaLivelloCompetenza(item.CodiceValutazione))
				this.$set(item, 'ColoreValutazione', this.decodificaColoreLivelloCompetenza(item.CodiceValutazione))
				var livelloAtteso = this.determinaLivelloCompetenzaAttesa(item.CodiceJob, item.CodiceCompetenza)
				this.$set(item, 'CodiceLivelloAtteso', livelloAtteso)
				this.$set(item, 'DescrizioneLivelloAtteso', this.decodificaEtichettaLivelloCompetenza(livelloAtteso))
				this.$set(item, 'ColoreLivelloAtteso', this.decodificaColoreLivelloCompetenza(livelloAtteso))
				var risultatoValutazione = livelloAtteso===-1 ? null : item.CodiceValutazione >= livelloAtteso
				this.$set(item, 'RisultatoValutazione', risultatoValutazione)
				this.$set(item, 'PersonaExt', this.decodificaFullNamePersona(item.IdPersona))
				this.$set(item, 'DescrizioneCompetenzaExt', this.decodificaNomeCompetenza(item.CodiceCompetenza))
			})
		},
		//
		editItem(item) {
			this.$set(this, 'currentValutazione', JSON.parse(JSON.stringify(item))) // disaccoppia e carica il record selezionato come current
			this.dialog = true
		},
		// salva un record di valutazione
		updateItem() {
			this.loading = true
			this.$store.dispatch('saveValutazione', this.currentValutazione).then(()=>{
				this.initData() // dopo che lo store ha ricaricato i dati la init provvede a prepararli per l'uso
				this.loading = false
				this.dialog = false
			})
		},
		// forza il refresh dei dati
		refreshList() {
			this.loading = true
			this.$store.dispatch('refreshValutazioni').then(()=>{
				this.initData() // dopo che lo store ha ricaricato i dati la init provvede a prepararli per l'uso
				this.loading = false
			})
		},
		// decodifica il nome di una persona
		decodificaFullNamePersona(id) {
			var dummy = this.persone.find(item => { return item.Id === id })
			if(dummy) return dummy.FullName
			return '-'
		},
		// estrae solo la descrizione delle competenza (con un check di esistenza che non si sa mai)
		decodificaNomeCompetenza(codiceCompetenza) {
			var dummy = this.competenze.find(e => e.CodiceCompetenza === codiceCompetenza)
			if(dummy) return dummy.NomeCompetenza + (dummy.Visibile ? '' : '  **ELIMINATA**')
			return '-'
		},
		decodificaDescrizioneJob(codice) {
			if(!codice) return '*extra job*'
			if(this.decodifiche && this.decodifiche.Jobs) {
				var dummy = this.decodifiche.Jobs.find(item => {return item.CodiceJob === codice})
				if(dummy) return dummy.DescrizioneJob
			} 
			return '-'
		},
		determinaLivelloCompetenzaAttesa(codiceJob, codiceCompetenza) {
			if(this.decodifiche && this.decodifiche.JobsCompetenze) {
				var dummy = this.decodifiche.JobsCompetenze.find(item => {return item.CodiceJob === codiceJob && item.CodiceCompetenza === codiceCompetenza})
				if(dummy) return dummy.LivelloAtteso
			} 
			return -1
		},
		decodificaEtichettaLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Livello'+livello]
			} 
			return null
		},
		decodificaColoreLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Colore'+livello]
			} 
			return null
		},
	}
}
</script>
