<template>
	<div>
		<v-menu
			v-model="menu1"
			:close-on-content-click="false"
			max-width="290"
			:disabled="readonly"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					:value="computedDateFormattedMomentjs"
					:clearable="clearable"
					:label="label"
					readonly
					:filled="filled"
					:solo="solo"
					:dense="dense"
					:outlined="outlined"
					:hide-details="hideDetails"
					:disabled="disabled"
					v-on="on"
					@click:clear="clearDate"
				/>
			</template>
			<v-date-picker
				v-model="date"
				locale="it-it"
				:first-day-of-week="1"
				reactive
				:min="min"
				:max="max"
			/>
		</v-menu>
		<div v-if="viewDebugElements">
			<code>value: {{ value }}</code><br>
			<code class="mt-1">date: {{ date }}</code>
			<code v-if="min" class="mt-1">min: {{ min }}</code>
			<code v-if="max" class="mt-1">max: {{ max }}</code>
		</div>
	</div>
</template>

<script>
/**********************************************************************************************
**** COMPONETE DATE PICKER STANDARD ***********************************************************
***********************************************************************************************
* personalizzazione del sistema date-picker di vuetify per semplificare il codice e uniformare la gestione delle date
*/
import moment from 'moment'
export default {
	name: 'HrDatePicker',
	props: {
		value: {
			type: String,
			default: () => {return null}
		},
		label :{
			type: String,
			default: () => {return 'Data'}
		},
		solo: {
			type: Boolean,
			default: () => {return false}
		},
		filled: {
			type: Boolean,
			default: () => {return false}
		},
		dense: {
			type: Boolean,
			default: () => {return false}
		},
		outlined: {
			type: Boolean,
			default: () => {return false}
		},
		hideDetails: {
			type: Boolean,
			default: () => {return false}
		},
		// disattiva totalmente il campo (in pratica come readonly solo che mette anche in grigetto il campo di testo)
		disabled: {
			type: Boolean,
			default: () => {return false}
		},
		// disattiva la possibilità di modificare la data
		readonly: {
			type: Boolean,
			default: () => {return false}
		},
		clearable: {
			type: Boolean,
			default: () => {return false}
		},
		// se true visualizza le date con il mese accorciato a tre caratteri
		shortDate: {
			type: Boolean,
			default: () => {return false}
		},
		min: {
			type: String,
			default: () => {return undefined}
		},
		max: {
			type: String,
			default: () => {return undefined}
		},
		// se true il clear (abilitato con clearable) porta il value a null
		// se false invece usa la data corrente (default = true)
		clearableAtNull: {
			type: Boolean,
			default: () => {return true}
		},

	},
	data: () => {
		return {
			menu1: false,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		computedDateFormattedMomentjs () {
			moment.locale('it')
			return this.date ? moment(this.date).format(this.shortDate ? 'DD MMM YYYY' : 'DD MMMM YYYY') : ''
		},
		date: {
			get: function () {
				return this.value ? moment(this.value).format('YYYY-MM-DD') : null
			},
			set: function (newValue) {
				var oldValue = this.value
				this.menu1 = false
				this.$emit('input', newValue? moment(newValue).format('YYYY-MM-DD') + 'T00:00:00' : newValue)
				this.$emit('change', {oldValue: oldValue, newValue: newValue}) 
			}
		},
	},
	created() {
		moment.locale('it')
	},
	methods: {
		clearDate() {
			this.date = this.clearableAtNull ? null : moment().format('YYYY-MM-DD')
		}
	}	
}
</script>

<style scoped lang="less">

</style>